import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";
import Status               from "dashboard/dist/Core/Status";

// Dialogs
import OptionalEdit         from "./OptionalEdit";
import OptionalItemEdit     from "./OptionalItemEdit";
import OptionalItemPartial  from "./OptionalItemPartial";
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import PromptDialog         from "dashboard/dist/Components/PromptDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import InfoList             from "dashboard/dist/Components/InfoList";
import InfoItem             from "dashboard/dist/Components/InfoItem";
import InfoAction           from "dashboard/dist/Components/InfoAction";
import NoneAvailable        from "dashboard/dist/Components/NoneAvailable";
import IconLink             from "dashboard/dist/Components/IconLink";
import Circle               from "dashboard/dist/Components/Circle";

// Actions
import {
    fetchOptional, editOptionalPartial, editOptionalItem, deleteOptionalItem,
} from "Actions/App/Product/OptionalActions";

// Styles
import "Styles/Components/App/Optionals.css";



/**
 * The Optional View Dialog
 */
class OptionalView extends React.Component {
    // The Current State
    state = {
        action  : Action.get(),
        elemID  : 0,
        loading : true,
        update  : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchOptional } = this.props;
        
        // View Opens
        if (open && !prevProps.open) {
            fetchOptional(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action, elemID : 0 });
    }

    /**
     * Starts an Action for an Item
     * @param {String}  actionName
     * @param {Number=} elemID
     * @returns {Void}
     */
    startEdition = (actionName, elemID = 0) => {
        const action = Action.get(actionName);
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @param {Boolean} update
     * @returns {Void}
     */
    endAction = (update) => {
        const { elemID, fetchOptional } = this.props;
        if (update !== undefined) {
            fetchOptional(elemID);
            this.setState({ action : Action.get(), elemID : 0, update });
        } else {
            this.setState({ action : Action.get(), elemID : 0 });
        }
    }
    
    /**
     * Ends an edit Action
     * @returns {Void}
     */
    endEdit = () => {
        this.endAction(true);
    }

    /**
     * Handles the Status Edit Submit
     * @param {Number} status
     * @returns {Promise}
     */
    editPartial = async (status) => {
        if (status) {
            const { elemID, editOptionalPartial } = this.props;
            const response = await editOptionalPartial({ optionalID : elemID, status });
            if (response.success) {
                this.endAction(true);
            }
        }
    }

    /**
     * Deletes the Optional Item
     * @returns {Promise}
     */
    deleteItem = async () => {
        if (this.state.elemID) {
            const { elemID, deleteOptionalItem } = this.props;
            await deleteOptionalItem(elemID, this.state.elemID);
            this.endAction(true);
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, elem, items, canEdit } = this.props;
        const { action, elemID, update, loading     } = this.state;
        
        const hasItems = Boolean(items.length);
        const forItem  = Boolean(elemID);
        const title    = loading ? "GENERAL_LOADING" : elem.name || "OPTIONALS_VIEW_TITLE";
        const elemName = Utils.getValue(items, "optionalItemID", elemID, "name");

        return <>
            <ViewDialog
                open={open}
                icon="optional"
                title={title}
                secondary={canEdit && !loading ? "OPTIONALS_ADD_ITEM_TITLE" : ""}
                onSecondary={() => this.startEdition("CREATE")}
                onClose={() => onClose(update)}
                isLoading={loading}
            >
                <InfoList onAction={this.startAction}>
                    <InfoItem label="GENERAL_NAME"       message={elem.name}       />
                    <InfoItem label="GENERAL_SHORT_NAME" message={elem.shortName}  />
                    <InfoItem label="GENERAL_STATUS"     message={elem.statusName} />
                    <InfoAction action="EDIT" />
                </InfoList>
                
                {!hasItems ? <NoneAvailable
                    variant="dialog"
                    message="OPTIONALS_NONE_ITEMS"
                /> : <ol className="optional-list no-list">
                    {items.map((item) => <li key={item.optionalItemID}>
                        <h3><Circle variant={item.statusColor} /> {item.name}</h3>
                        <div className="optional-price">$ {item.price}</div>
                        <div className="optional-actions">
                            <IconLink
                                variant="light"
                                icon="edit"
                                onClick={() => this.startEdition("EDIT", item.optionalItemID)}
                            />
                            {canEdit && <IconLink
                                variant="light"
                                icon="delete"
                                onClick={() => this.startEdition("DELETE", item.optionalItemID)}
                            />}
                        </div>
                    </li>)}
                </ol>}
            </ViewDialog>
            
            <OptionalEdit
                open={canEdit && !forItem && action.isEdit}
                elemID={elem.optionalID}
                clientID={elem.clientID}
                onSubmit={this.endEdit}
                onClose={this.endAction}
            />
            <PromptDialog
                open={!canEdit && !forItem && action.isEdit}
                icon="edit"
                title="OPTIONALS_EDIT_TITLE"
                inputType="select"
                inputOptions={Status.getSelect()}
                initialValue={elem.status}
                onSubmit={this.editPartial}
                onClose={this.endAction}
            />

            <OptionalItemEdit
                open={canEdit && (action.isCreate || (forItem && action.isEdit))}
                elemID={elemID}
                optionalID={elem.optionalID}
                onSubmit={this.endEdit}
                onClose={this.endAction}
            />
            <OptionalItemPartial
                open={!canEdit && forItem && action.isEdit}
                elemID={elemID}
                onSubmit={this.endEdit}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={canEdit && forItem && action.isDelete}
                title="OPTIONALS_DELETE_ITEM_TITLE"
                message="OPTIONALS_DELETE_ITEM_TEXT"
                content={elemName}
                onSubmit={this.deleteItem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOptional       : PropTypes.func.isRequired,
        editOptionalPartial : PropTypes.func.isRequired,
        editOptionalItem    : PropTypes.func.isRequired,
        deleteOptionalItem  : PropTypes.func.isRequired,
        open                : PropTypes.bool.isRequired,
        onClose             : PropTypes.func.isRequired,
        onSubmit            : PropTypes.func.isRequired,
        edition             : PropTypes.number.isRequired,
        elem                : PropTypes.object.isRequired,
        items               : PropTypes.array.isRequired,
        canEdit             : PropTypes.bool.isRequired,
        elemID              : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.optional.edition,
            elem    : state.optional.elem,
            items   : state.optional.items,
            canEdit : state.optional.canEdit,
        };
    }
}

export default connect(OptionalView.mapStateToProps, {
    fetchOptional, editOptionalPartial, editOptionalItem, deleteOptionalItem,
})(OptionalView);
